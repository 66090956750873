defineDs('DanskeSpil/Domain/Feature.News/Scripts/NewsList.js', [], function () {
  let delayTimer;
  const allNews = document.querySelectorAll('.js-news-list-item');
  const noResults = document.getElementById('js-news-list-no-results');

  const searchBar = document.getElementById('js-news-list-search');
  if (searchBar) {
    searchBar.addEventListener('input', () => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function () {
        filterHandler();
      }, 300);
    });
  }

  const select = document.getElementById('js-news-list-select');
  if (select) {
    select.addEventListener('change', () => {
      filterHandler(true);
    });
  }

  const loadAll = document.getElementById('js-news-list-see-all');
  if (loadAll) {
    loadAll.addEventListener('click', () => {
      const newsSelect = select.value;
      const searchValue = searchBar.value;

      allNews.forEach((news) => {
        const newsCategory = news.getAttribute('data-category');
        const newsTitle = news.querySelector('.js-news-list-item-title').textContent;

        if ((newsCategory === newsSelect || newsSelect === 'all') && newsTitle.toLowerCase().includes(searchValue.toLowerCase())) {
          news.style.display = 'block';
        } else {
          news.style.display = 'none';
        }
      });

      loadAll.style.display = 'none';
    });
  }

  function filterHandler(ignoreSearchLength = false) {
    const searchValue = searchBar.value;
    const newsSelect = select.value;
    const maxNews = 5;
    let foundNews = 0;

    allNews.forEach((news) => {
      const newsCategory = news.getAttribute('data-category');
      const newsTitle = news.querySelector('.js-news-list-item-title').textContent;

      if ((newsCategory === newsSelect || newsSelect === 'all') && foundNews < maxNews && (ignoreSearchLength || newsTitle.toLowerCase().includes(searchValue.toLowerCase()))) {
        news.style.display = 'block';
        foundNews++;
      } else {
        news.style.display = 'none';
      }
    });

    if (foundNews === 0) {
      noResults.style.display = 'block';
      loadAll.style.display = 'none';
    } else {
      noResults.style.display = 'none';

      if (foundNews < maxNews) {
        loadAll.style.display = 'none';
      } else {
        loadAll.style.display = 'block';
      }
    }
  }
});
