defineDs('DanskeSpil/Domain/Feature.Components/Scripts/CategoryEntrance.js', [], function () {
  const scrollers = document.querySelectorAll('.js-category-entrance .scroller__inner');
  if (scrollers) {
    scrollers?.forEach((scroller) => {
      const brandsLength = scroller.getAttribute('data-brandlength');
      // add animation-duration to scroller__inner
      scroller.style.animationDuration = `${brandsLength * 6}s`;
    });
  }
});
