defineDs('DanskeSpil/Domain/Feature.Components/Scripts/AnimatedNumber.js', [], function () {

  function normalize(val, max, min) {
    return (val - min) / (max - min);
  }
  function easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }

  const numbersToAnimate = document.querySelectorAll('.js-animated-number');

  numbersToAnimate.forEach(function (numberElement) {
    const settings = JSON.parse(numberElement.dataset.settings);
    const endTime = 2500;
    const endCount = settings.number;
    const endCountString = endCount.toString();
    const decimals = endCountString.split('.')[1]?.length || 0;
    const countText = numberElement.querySelector('.js-animated-number-text');
    let hasAnimated = false;
    let animationStart = null;
    let count = '0';

    // eslint-disable-next-line compat/compat
    let observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !hasAnimated) {
        hasAnimated = true;
        animationStart = performance.now();
        requestAnimationFrame(animate);
      }
    }, { threshold: 0.5 });
    observer.observe(numberElement);

    const animate = () => {
      let duration = performance.now() - animationStart;
      const normalizedDuration = normalize(duration, endTime, 0);
      const ratio = easeOutSine(normalizedDuration);

      count = (ratio * endCount).toLocaleString('da-DK', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
      countText.innerText = `${count} ${settings.unit}`;
      if (duration > endTime) {

        count = endCount.toLocaleString('da-DK', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
        countText.innerText = `${count} ${settings.unit}`;
        return;
      }
      requestAnimationFrame(animate);
    };
  });
});