defineDs('DanskeSpil/Domain/Feature.Components/Scripts/Sliders',
  [
    'Common/Framework/Foundation.SharedScripts/Scripts/Libraries/CustomSlider'
  ], function (CustomSlider) {

    const sliderElements = document.querySelectorAll('.js-slider-wrapper');
    sliderElements.forEach((sliderElement) => {
      const sliders = sliderElement.querySelector('.js-custom-slider');
      const sliderParent = sliders.parentElement;
      const indicators = sliderParent.querySelectorAll('.js-slider-indicators > div');
      const alignElementWithFirstSlide = sliderElement.querySelector('.js-slider-title');

      const slider = new CustomSlider({
        containerSelector: sliders,
        alignElementWithFirstSlide,
        controls: {
          enabled: true,
          next: sliderParent.querySelector('.js-slider-next'),
          previous: sliderParent.querySelector('.js-slider-previous'),
          disabledClasses: ['!opacity-0', '!pointer-events-none']
        },
        elementClasses: {
          general: ['shrink-0', 'transition-opacity', 'duration-300', 'ease-in-out'],
          passive: ['opacity-50', 'pointer-events-none'],
          active: ['opacity-100'],
        },
        settings: {
          center: true,
          enableMouseDrag: false,
          slideBy: 1,
          maxGroupSize: 3
        }
      });


      slider.onPercentageChange = (percentage, towards, awayFrom) => {
        const opacity = scaleValue(
          Math.abs(percentage),
          [0, 100],
          [0.5, 1]
        );

        if (slider._slides[towards]) slider._slides[towards].style.opacity = opacity;
        if (slider._slides[awayFrom]) slider._slides[awayFrom].style.opacity = (1.5 - opacity);
      };



      if (slider._groupSize >= slider._slides.length) {
        indicators.forEach((indicator) => indicator.classList.add('hidden'));
      }
      const updateIndicator = (activeIndicator) => {
        indicators.forEach((indicator, i) => {
          indicator.setAttribute('data-active', `${i === activeIndicator}`);
        });
      };
      updateIndicator(0);

      slider.onSlideCallback = (indices) => {
        let activeIndicator;
        if (indices.includes(0)) activeIndicator = 0;
        else if (indices.includes(slider._slides.length - 1)) activeIndicator = 2;
        else activeIndicator = 1;

        updateIndicator(activeIndicator);
      };

      window.addEventListener('resize', () => {
        handleSliderControls(sliderElement);
      });

      handleSliderControls(sliderElement);
    });

    function scaleValue(value, from, to) {
      const scale = (to[1] - to[0]) / (from[1] - from[0]);
      const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
      return (capped * scale) + to[0];
    }

    function isDeviceTouchable() {
      return (('ontouchstart' in window) ||
        // eslint-disable-next-line compat/compat
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
    }

    function handleSliderControls(element) {
      element.closest('.group').classList.toggle('js-force-show-controls', !isDeviceTouchable());
    }
  });
