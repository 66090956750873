defineDs('Common/Framework/Foundation.SharedScripts/Scripts/ContentData/MostPopularOddsetBet.js', [], function () {

  const oddsetTiles = document.querySelectorAll('.js-oddset-feed');

  if (oddsetTiles.length === 0) return;

  const oneMinuteInMs = 60000;

  function getValue(value) {
    value = value?.toFixed(2)?.toString()?.replace('.', ',');
    return value ?? null;
  }

  function fetchData() {
    fetch('https://feeds-gateway.dsservice.eu/bis/exposer/api/v1/popularbets?limit=1')
      .then((response) => response.json())
      .then((data) => {
        if (!data) return;
        let {
          nameHome,
          nameAway,
          oddsHome,
          oddsEven,
          oddsAway,
        } = data[0];

        oddsHome = getValue(oddsHome);
        oddsEven = getValue(oddsEven);
        oddsAway = getValue(oddsAway);

        // loop through all oddsetTiles and update the content with the fetched data
        oddsetTiles.forEach((element) => {
          element.querySelector('.js-oddset-feed-nameHome').textContent = nameHome;
          element.querySelector('.js-oddset-feed-nameAway').textContent = nameAway;
          element.querySelector('.js-oddset-feed-oddsHome').textContent = oddsHome;

          if (oddsEven) {
            element.querySelector('.js-oddset-feed-oddsEven').textContent = oddsEven;
            element.querySelector('.js-oddset-feed-oddsEven').style.display = '';
          } else {
            element.querySelector('.js-oddset-feed-oddsEven').style.display = 'none';
          }

          element.querySelector('.js-oddset-feed-oddsAway').textContent = oddsAway;
          element.classList.remove('hidden');
          element.dataset.fetch = 'success';
        });
      })
      .catch((error) => {
        oddsetTiles.forEach((element) => {
          element.dataset.fetch = 'error';
        });
        console.error('MostPopularOddsetBet fetch error:', error);
      });
  }

  function initialize() {
    fetchData();
    setInterval(fetchData, oneMinuteInMs);
  }

  initialize();

});
