defineDs('DanskeSpil/Domain/Feature.Components.Dlo/Scripts/WinningNumbers', [], function () {

  const winningNumbersElement = document.querySelector('.js-winning-numbers');

  if (!winningNumbersElement) {
    return;
  }

  const getElementLeft = (element) => {
    let positionLeft = 0;
    while (element) {
      positionLeft += element.offsetLeft;
      element = element.offsetParent;
    }
    return positionLeft;
  };

  let isDesktop;
  const gamesElement = winningNumbersElement.querySelector('.js-winning-numbers-games');
  const gamesSelectorElement = winningNumbersElement.querySelector('.js-winning-numbers-games-selector');
  const gameElements = gamesElement.querySelectorAll('li');
  const boxElement = winningNumbersElement.querySelector('.js-winning-numbers-box');
  const games = JSON.parse(winningNumbersElement.dataset.games);

  let previousSelectedGame;

  const getGameIndex = (selectedGame) => games.findIndex((game) => game === selectedGame);

  const getMiddlePointsBetweenGames = (arr) => arr.slice(0, -1).map((val, i) => Math.floor((val + arr[i + 1]) / 2));

  const setSelectedGame = (game) => {
    winningNumbersElement.setAttribute('data-selected-game', game);
    toggleBallAnimation();
  };

  const getGameLeftPositions = () => {
    const paddingLeft = isDesktop ? 0 : 500;
    const gamesGap = 10;

    const gamesElLeft = getElementLeft(gamesElement);

    const games = gamesElement.querySelector('ul');
    const gameWidths = [...games.querySelectorAll('li')].map((li) => li.offsetWidth);

    const arr = [];
    let totalWidth = 0;

    for (let i = 0; i < gameWidths.length; i++) {
      let scr = Math.floor(gamesElLeft + paddingLeft - (window.innerWidth / 2) + totalWidth + (gameWidths[i] / 2));
      arr.push(scr);
      totalWidth += gameWidths[i] + gamesGap;
    }

    return arr;
  };

  const getSelectedGame = () => {
    let selectedGame = winningNumbersElement.getAttribute('data-selected-game');

    if (!selectedGame) {
      selectedGame = games[0];
      setSelectedGame(selectedGame);
    }

    return selectedGame;
  };

  const setBoxLeftPositionMobile = () => {
    const gamesElLeft = getElementLeft(gamesElement);
    const boxElLeft = getElementLeft(boxElement);
    const clipPathPosition = window.innerWidth - (boxElLeft - gamesElement.scrollLeft + gamesElLeft);

    gamesSelectorElement.style = `--clipPathPosition: ${clipPathPosition}px;`;
  };

  const setBoxLeftPositionDesktop = () => {
    const selectedGame = getSelectedGame();
    const selectedGameIndex = getGameIndex(selectedGame);

    const gameElement = gamesElement.querySelector(`ul li:nth-child(${selectedGameIndex + 1})`);
    const gameWidth = gameElement.offsetWidth;
    const gamePosition = gameElement.offsetLeft;

    const clipPathPosition = gamePosition + (gameWidth / 2);
    const spacingBeforeList = gamesElement.querySelector('ul').offsetLeft;
    const boxPosition = gamePosition + spacingBeforeList;

    gamesSelectorElement.style = `--boxPosition: ${boxPosition}px; --clipPathPosition: ${clipPathPosition}px;`;
  };

  const scrollGamesElementToSelectedGame = (selectedGame = getSelectedGame()) => {
    const gameLeftPositions = getGameLeftPositions();
    const selectedGameIndex = getGameIndex(selectedGame);
    gamesElement.scrollTo(gameLeftPositions[selectedGameIndex], 0);
  };

  const handleClickEvent = (i) => {
    setSelectedGame(games[i]);

    if (isDesktop) {
      setBoxLeftPositionDesktop();
    } else {
      scrollGamesElementToSelectedGame(games[i]);
    }
  };

  const addClickHandlers = () => {
    gameElements.forEach((gameElement, i) => {
      gameElement.addEventListener('click', () => handleClickEvent(i));
    });
  };

  const handleScrollEvent = () => {
    const snapBuffer = 30;
    const gameLeftPositions = getGameLeftPositions();
    const currentSliderPos = gamesElement.scrollLeft;
    const middlePoints = getMiddlePointsBetweenGames(gameLeftPositions);
    let currentSelectedGame;

    for (let i = 0; i < middlePoints.length; i++) {
      currentSelectedGame = games[i + 1];

      if ((currentSliderPos - snapBuffer) >= middlePoints[i] && (currentSliderPos + snapBuffer) < (middlePoints[i + 1] || Infinity)) {
        if (previousSelectedGame !== currentSelectedGame) {
          setSelectedGame(currentSelectedGame);
          previousSelectedGame = currentSelectedGame;
        }
        break;
      }
    }

    if ((currentSliderPos + snapBuffer) < middlePoints[0]) {
      currentSelectedGame = games[0];
      if (previousSelectedGame !== currentSelectedGame) {
        setSelectedGame(currentSelectedGame);
        previousSelectedGame = currentSelectedGame;
      }
    }

    setBoxLeftPositionMobile();
  };

  const toggleBallAnimation = () => {
    winningNumbersElement.classList.add('no-animate');
    winningNumbersElement.classList.remove('animate');

    setTimeout(() => {
      winningNumbersElement.classList.remove('no-animate');
      winningNumbersElement.classList.add('animate');
    }, 0);
  };

  const initialize = () => {
    winningNumbersElement.classList.add('initialize');
    isDesktop = window.matchMedia('(min-width: 1024px)').matches;

    gamesElement.removeEventListener('scroll', handleScrollEvent);

    if (isDesktop) {
      setBoxLeftPositionDesktop();
    } else {
      scrollGamesElementToSelectedGame();
      gamesElement.addEventListener('scroll', handleScrollEvent);
    }

    setTimeout(() => {
      winningNumbersElement.classList.remove('initialize');
      toggleBallAnimation();
    }, 50);
  };

  addClickHandlers();

  initialize();
  window.addEventListener('resize', initialize);

});
