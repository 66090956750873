defineDs('DanskeSpil/Domain/Feature.Components/Scripts/FaktaBox.js', [], function () {

  if (document.querySelector('.js-faktabox')) {
    const faktaBoxes = document.querySelectorAll('.js-faktabox');

    faktaBoxes?.forEach((box) => {
      const toggle = box.querySelector('.js-faktabox-toggle');
      toggle?.addEventListener('click', () => {
        faktaBoxToggle(box, toggle);
      });

      if (window.innerWidth < 640) {
        box?.querySelector('.js-faktabox__inner ').addEventListener('click', () => {
          faktaBoxToggle(box, toggle);
        });
      }

      box.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          faktaBoxToggle(box, toggle);
        }
      });
    });


    const faktaBoxToggle = (box, toggle) => {
      box.classList.toggle('open');
      box.querySelector('.js-faktabox__content').setAttribute('aria-hidden', !box.classList.contains('open'));
      toggle.setAttribute('aria-expanded', box.classList.contains('open'));
      toggle.classList.toggle('open');
    };
  }
});
