defineDs('DanskeSpil/Domain/Feature.Components.Dli/Scripts/DliWheels',
  [
    'Common/Framework/Foundation.SharedScripts/Scripts/Libraries/WheelSlider',
  ], function (WheelSlider) {

    const wheel = document.querySelector('.js-dli-wheel-container');

    if (!wheel) return;

    let prevWidth = window.innerWidth;
    let bigTilesDiffAngle = 22;
    let bigTilesRotationMultiplier = 0.568;
    let smallTilesDiffAngle = 12.75;
    let smallTilesRotationMultiplier = 0.47;

    const wheelSlider = new WheelSlider({
      containerSelector: wheel,
      controls: {
        enabled: true,
        next: '.js-dli-wheel-next',
        previous: '.js-dli-wheel-prev'
      },
      elementClasses: {
        general: ['!opacity-100'],
      },
      settings: {
        enableMobileDrag: false,
        diffAngle: window.innerWidth >= 1024 ? bigTilesDiffAngle : smallTilesDiffAngle,
        rotationMultiplier: window.innerWidth >= 1024 ? bigTilesRotationMultiplier : smallTilesRotationMultiplier,
      }
    });

    window.addEventListener('resize', function () {
      if (window.innerWidth <= 1023 && prevWidth >= 1024) {
        wheelSlider.updateSettings({
          diffAngle: smallTilesDiffAngle,
          rotationMultiplier: smallTilesRotationMultiplier
        });

        // reinitialize wheelSlider on resize/orientation change
        wheelSlider.init();
      } else if (window.innerWidth >= 1024 && prevWidth <= 1023) {
        wheelSlider.updateSettings({
          diffAngle: bigTilesDiffAngle,
          rotationMultiplier: bigTilesRotationMultiplier
        });

        // reinitialize wheelSlider on resize/orientation change
        wheelSlider.init();
      }
      prevWidth = window.innerWidth;
    }, false);

    wheelSlider.onNextClick = () => handleControlClick('next');
    wheelSlider.onPreviousClick = () => handleControlClick('previous');

    const handleControlClick = (direction) => {
      const angleToMove = 20 * (direction === 'next' ? -1 : 1);
      wheelSlider.updateTotalAngleShift(angleToMove);
    };

    // add event listener for all .js-logo-link elements
    const logoLinks = document.querySelectorAll('.js-logo-link');
    logoLinks.forEach((logoLink) => {
      if (!logoLink.getAttribute('href')) return;

      logoLink.addEventListener('click', function (e) {
        e.preventDefault();
        const url = this.getAttribute('href');
        window.location.href = url;
      });
    });
  });
