defineDs('DanskeSpil/Domain/Feature.Components.Dlo/Scripts/Wheels',
  [
    'Common/Framework/Foundation.SharedScripts/Scripts/Libraries/WheelSlider',
    'DanskeSpil/Domain/Feature.Components/Scripts/Utils/SliderUtils',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
  ], function (WheelSlider, SliderUtils, ApiRequest) {

    const wheel = document.querySelector('.js-wheel-container');
    if (!wheel) return;

    const dotsWrapper = document.querySelector('.js-dots-wrapper');
    const wheelStopper = document.querySelector('.js-wheel-stopper');
    const wheelHerosWrapper = document.querySelector('.js-dlo-wheel-heros');

    const dotsWrapperRect = dotsWrapper.getBoundingClientRect();
    let dotRects = [];
    let dots = [];
    let prevWidth = window.innerWidth;

    let bigTilesDiffAngle = 20;
    let bigTilesRotationMultiplier = 0.95;
    let smallTilesDiffAngle = 15;
    let smallTilesRotationMultiplier = 1;

    const wheelSlider = new WheelSlider({
      containerSelector: wheel,
      controls: {
        enabled: true,
        next: '.js-wheel-next',
        previous: '.js-wheel-prev'
      },
      elementClasses: {
        general: ['!opacity-100'],
      },
      settings: {
        enableMobileDrag: false,
        diffAngle: window.innerWidth > 768 ? bigTilesDiffAngle : smallTilesDiffAngle,
        rotationMultiplier: window.innerWidth > 768 ? bigTilesRotationMultiplier : smallTilesRotationMultiplier,
      }
    });

    window.addEventListener('resize', function () {
      if (window.innerWidth <= 767 && prevWidth >= 768) {
        wheelSlider.updateSettings({
          diffAngle: smallTilesDiffAngle,
          rotationMultiplier: smallTilesRotationMultiplier
        });

        // reinitialize wheelSlider on resize/orientation change
        wheelSlider.init();
      } else if (window.innerWidth >= 768 && prevWidth <= 767) {
        wheelSlider.updateSettings({
          diffAngle: bigTilesDiffAngle,
          rotationMultiplier: bigTilesRotationMultiplier
        });

        // reinitialize wheelSlider on resize/orientation change
        wheelSlider.init();
      }
      prevWidth = window.innerWidth;
    }, false);

    wheelSlider.onSlideCallback = (slides) => {
      if (slides) {
        dotsWrapper.classList.remove('opacity-0');
        wheelHerosWrapper.classList.remove('opacity-0');
      }
    };

    if (dotsWrapper && wheelStopper) {
      dots = dotsWrapper.querySelectorAll('div');
      const wheelStopperRect = wheelStopper.getBoundingClientRect();
      const wheelStopperCenter = wheelStopperRect.left + (wheelStopperRect.width / 2);
      dotRects = [...dots].map((dot) => dot.getBoundingClientRect());
      let intersectionX = 0;

      wheelSlider.onFrame = () => {
        dotRects = [...dots].map((dot) => dot.getBoundingClientRect());
      };

      let prevClosest;
      let closestAtIntersection;
      let lastClosestRect;
      let intersectionCount = 0;
      dots.forEach((dot, i) => {
        const dotAngle = ((i * 4) - 90) - 10.5;
        const coords = SliderUtils.getCoordsFromAngle(dotsWrapperRect, dotAngle);
        dot.dataset.angle = dotAngle.toString();
        dot.style.transform = `translate(${coords.x}px, ${coords.y}px)`;
      });

      wheelSlider.onTotalAngleShiftChange = function (draggedXPosition) {

        dots.forEach((dot, i) => {
          const dotAngle = ((i * 4) - 90) + (draggedXPosition) - 10;
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapperRect, dotAngle);
          dot.dataset.angle = dotAngle.toString();
          dot.style.transform = `translate(${coords.x}px, ${coords.y}px)`;
        });

        const { closestDot, closestDotIndex } = getClosestDot(dots);
        const closestDotRect = dotRects[closestDotIndex];

        const intersecting = isIntersecting(closestDotRect, wheelStopperRect);
        const skippedDirection = hasSkippedIntersection(lastClosestRect, closestDotRect, wheelStopperCenter, wheelStopperRect, closestDot, prevClosest);

        if (intersecting && closestAtIntersection !== closestDot) {
          intersectionX = draggedXPosition;
          closestAtIntersection = closestDot;
          intersectionCount = 1;
          wheelStopper.style.transitionDuration = '0s';
        } else if (skippedDirection) {
          intersectionCount = 0;
          animateWheelStopper(skippedDirection, 0);
        } else if (!intersecting && intersectionCount && intersectionCount <= 3) {
          intersectionCount = 0;
          animateWheelStopper(intersectionX > draggedXPosition ? 'next' : 'previous', 0);
        } else if (!intersecting && intersectionCount > 3) {
          wheelStopper.style.transitionDuration = '';
          wheelStopper.style.setProperty('--tw-rotate', '0');
          closestAtIntersection = null;
          intersectionCount = 0;
        } else if (intersecting) {
          intersectionCount++;
          const newRotation = Math.min(Math.max((intersectionX - draggedXPosition) * 20, -40), 40);
          wheelStopper.style.setProperty('--tw-rotate', newRotation + 'deg');
        }

        prevClosest = closestDot;
        lastClosestRect = closestDotRect;
      };

      wheelSlider.onDragEnd = function () {
        const { closestDotIndex } = getClosestDot(dots);

        const closestDotRect = dotRects[closestDotIndex];
        wheelStopper.style.setProperty('--tw-rotate', '0');

        const c1 = {
          x: dotsWrapperRect.left + (dotsWrapperRect.width / 2),
          y: (dotsWrapperRect.height / 2) + dotsWrapperRect.top
        };
        const c2 = {
          x: closestDotRect.left + (closestDotRect.width / 2),
          y: closestDotRect.top + (closestDotRect.height / 2)
        };
        const c3 = { x: dotsWrapperRect.left + (dotsWrapperRect.width / 2), y: dotsWrapperRect.top };

        const angleToMove = (SliderUtils.angle(c1, c2, c3) * 0.8) - 10;

        wheelSlider.updateTotalAngleShift((c1.x > c2.x ? angleToMove : -angleToMove));

        dots.forEach((dot) => {
          const dotAngle = Number(dot.dataset.angle);
          const newDotAngle = dotAngle + (c1.x > c2.x ? angleToMove : -angleToMove);
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapperRect, newDotAngle);
          dot.style.transform = `translate(${coords.x}px, ${coords.y}px)`;
          dot.dataset.angle = newDotAngle.toString();
        });
      };

      wheelSlider.onNextClick = () => handleControlClick(dots, 'next');
      wheelSlider.onPreviousClick = () => handleControlClick(dots, 'previous');

    }

    const handleControlClick = (dots, direction) => {
      const angleToMove = 20 * (direction === 'next' ? -1 : 1);
      setTimeout(() => {
        animateWheelStopper(direction, 50);
      }, 100);
      wheelSlider.updateTotalAngleShift(angleToMove);

      dots.forEach((dot) => {
        dot.style.transitionDuration = '0s';

        let start;
        let previousTimestamp;
        const moveDotOnButtonClick = (timestamp) => {
          if (!start) start = timestamp;
          const elapsed = Math.min(Math.max((previousTimestamp || timestamp) - start, 1), 300);
          const eased = SliderUtils.easeOut((elapsed / 300));
          const x = 20 * eased * (direction === 'next' ? -1 : 1);
          const dotAngle = Number(dot.dataset.angle);
          const newDotAngle = dotAngle + x;
          const coords = SliderUtils.getCoordsFromAngle(dotsWrapperRect, newDotAngle);
          dot.style.transform = `translate(${coords.x}px, ${coords.y}px)`;
          previousTimestamp = timestamp;

          if (Math.abs(elapsed) >= 300) {
            dot.style.transitionDuration = '';
            dot.dataset.angle = newDotAngle.toString();
            return;
          }
          requestAnimationFrame(moveDotOnButtonClick);
        };
        requestAnimationFrame(moveDotOnButtonClick);
      });
    };

    const animateWheelStopper = (direction, duration) => {
      const wheelStopperTransitionEnd = () => {
        wheelStopper.style.transitionDuration = '';
        wheelStopper.style.setProperty('--tw-rotate', '0');
        wheelStopper.removeEventListener('transitionend', wheelStopperTransitionEnd);
      };

      wheelStopper.addEventListener('transitionend', wheelStopperTransitionEnd);
      wheelStopper.style.transitionDuration = Math.max(duration, 1) + 'ms';
      wheelStopper.style.setProperty('--tw-rotate', (40 * (direction === 'next' ? 1 : -1)) + 'deg');
    };

    const getClosestDot = (dots) => {
      let shortestDistance = Number.MAX_SAFE_INTEGER;
      let closestDot;
      let closestDotIndex;

      for (const [i, dot] of [...dots].entries()) {
        if (!dot.classList.contains('js-wheel-special-dot')) continue;
        const dotRect = dotRects[i];

        const distance = Math.abs(SliderUtils.getDistance(
          { x: dotRect.left + (dotRect.width / 2), y: dotRect.top + (dotRect.height / 2) },
          { x: wheelSlider._centerX + wheelSlider._containerRect.left, y: wheelSlider._centerY + wheelSlider._containerRect.top }
        ));

        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestDot = dot;
          closestDotIndex = i;
        }
      }
      return { closestDot, closestDotIndex };
    };

    const isIntersecting = (closestDotRect, wheelStopperRect) => !(closestDotRect.right < wheelStopperRect.left + 4 || closestDotRect.left > wheelStopperRect.right - 4);
    const hasSkippedIntersection = (lastClosestRect, closestDotRect, wheelStopperCenter, wheelStopperRect, closestDot, prevClosest) => {
      if (!isIntersecting(closestDotRect, wheelStopperRect) && lastClosestRect && closestDot === prevClosest) {
        const lastDotCenter = lastClosestRect.left + (lastClosestRect.width / 2);
        const closestDotCenter = closestDotRect.left + (closestDotRect.width / 2);
        if (wheelStopperCenter < lastDotCenter && wheelStopperCenter > closestDotCenter) {
          return 'next';
        }

        if (wheelStopperCenter > lastDotCenter && wheelStopperCenter < closestDotCenter) {
          return 'previous';
        }

        return false;
      }
    };

    // ---------------------------- COUNTDOWN AND LOTTIE --------------------------------

    const addLottie = async (wheelHero, lottieSrc) => {
      const player = wheelHero.querySelector('.multi-client-top-spot__lottie lottie-player');
      if (!player || !lottieSrc) {
        return;
      }

      let strLottie = JSON.stringify(await ApiRequest({ url: lottieSrc }));
      player.load(strLottie);
    };

    const setupLottie = async (wheelHero) => {
      const lottieSrc = wheelHero.querySelector('.multi-client-top-spot__lottie')?.dataset.lottiesrc;
      if (lottieSrc) {
        await addLottie(wheelHero, lottieSrc);
      }
    };

    const wheelHeros = document.querySelectorAll('.js-dlo-wheel-hero');
    wheelHeros.forEach((wheelHero) => {
      const dataCardId = wheelHero.dataset.cardId;
      const drawingDayElement = wheelHero.querySelector('.js-lead-text');
      const drawingDayElementTile = wheel.querySelector('div[data-card-id="' + dataCardId + '"] .js-lead-text');
      const countdownData = wheelHero.querySelector('.js-countdown');
      // check if countdownData has countdown date and element contain text "{day}" and replace it with countdownData.dataset.countdown
      if (drawingDayElement.textContent.includes('{day}')) {
        if (countdownData && countdownData?.dataset?.countdown) {
          const weekday = new Intl.DateTimeFormat('da-DK', { weekday: 'long' }).format(new Date(countdownData.dataset.countdown));

          drawingDayElement.textContent = drawingDayElement.textContent.replace('{day}', weekday);
          drawingDayElementTile.textContent = drawingDayElement.textContent.replace('{day}', weekday);
        } else {
          drawingDayElement.textContent = drawingDayElement.textContent.replace('{day}', '');
          drawingDayElementTile.textContent = drawingDayElement.textContent.replace('{day}', '');
        }
      }

      setupLottie(wheelHero);
    });
  });
